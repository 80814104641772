<template>
  <div>
    <!-- Main Drawer -->
    <v-navigation-drawer app
                        v-model="drawer"
                        hide-overlay
                        :width="getNavBarWidth"
                        :stateless="true"
                        color="primary"
                        style="z-index: 1000">
      <v-layout column fill-height>
        <div id="hoverCollapse">
          <v-img class="flex-grow-0 align-center clickable" :class="{ 'text-right': !isOnMobile}"  :height="64" contain src="@/assets/Logo_S2.png" @click="clickNavBarHeader">
            <v-icon v-if="isOnMobile" class="nav-text--text ml-4" size="32px">mdi-arrow-left</v-icon>
            <v-icon v-else class="nav-text--text">{{ collapsed ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
          </v-img>
        </div>
        <v-divider class="nav-text"/>
        <v-list class="py-0" density="compact">
          <v-list-item-group :value="getSelectedNavItem.route.name" mandatory>
            <router-link v-for="navItem in getNavItems" :to="navItem.route" :key="navItem.title" class="text-decoration-none">
              <v-list-item :value="navItem.title" class="nav-menu__item d-flex pa-0" :class="{'justify-center': getMobileCollapsed}"  @click="clickNavBar(navItem.route.name)">
                <v-list-item-icon class="ma-0 pa-0 align-self-center" :class="{'pl-4': !getMobileCollapsed}">
                  <v-icon size="20px" class="nav-text--text">{{ navItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content v-if="navItem.route.name == RouteNames.PRICKING && !getMobileCollapsed"
                    class="ml-1 pa-0 text-uppercase small-caps font-weight-bold nav-text--text text-left nav-menu__text">
                  {{ $store.state.clockingsModule.pricking ? prickingTime : navItem.title }}
                </v-list-item-content>
                <v-list-item-content v-else-if="!getMobileCollapsed"
                    class="ml-1 pa-0 text-uppercase small-caps font-weight-bold nav-text--text text-left nav-menu__text">
                  {{ navItem.title }}
                </v-list-item-content>
              </v-list-item>
            </router-link>
          </v-list-item-group>
        </v-list>
        <CopyRight />
      </v-layout>
    </v-navigation-drawer>

    <MobileSubNavigationMenu ref="subDrawerRef" :drawer=mobileDrawer :parentNavItem=getSelectedNavItem @toggleDrawer="toggleMobileDrawer()" />
  </div>
</template>

<script>

import RouteNames from "@/router/RouteNames"
import {
  hasAccessToRoute,
  organizationHasModuleByRoute,
  organizationHasParameterTeamsByRoute
} from "@/shared/utils/authenticationUtils"
import {routeActionIsEnabled} from "@/shared/utils/parameterUtils"
import {intervalToDuration} from "date-fns"
import CopyRight from "./CopyRight.vue"
import MobileSubNavigationMenu from "./MobileSubNavigationMenu.vue"
import { DetailedTimeStamp } from "@/shared/types/DetailedTimeStamp";

export default {
  name: "NavigationMenu",
  components: {
    CopyRight,
    MobileSubNavigationMenu
  },
  data() {
    return {
      navItems: [
        {title: RouteNames.PRICKING, icon: 'mdi-clock', route: {name: RouteNames.PRICKING}, activating_routes: []},
        {title: RouteNames.CALENDAR, icon: 'mdi-calendar', route: {name: RouteNames.CALENDAR}, activating_routes: [RouteNames.DAY]},
        {title: RouteNames.PLANNING_USER, icon: 'mdi-calendar-check', route: {name: RouteNames.PLANNING_USER}, activating_routes: [RouteNames.PLANNING_USER]},
        {title: RouteNames.USER_TEAM_OVERVIEW, icon: 'mdi-face-man', route: {name: RouteNames.USER_TEAM_OVERVIEW}, activating_routes: []},
        {title: RouteNames.CLIENT_OVERVIEW, icon: 'mdi-contacts', route: {name: RouteNames.CLIENT_OVERVIEW}, activating_routes: []},
        {title: RouteNames.PROJECT_OVERVIEW, icon: 'mdi-dns-outline', route: {name: RouteNames.PROJECT_OVERVIEW}, activating_routes: [RouteNames.PROJECT_DETAIL]},
        {title: RouteNames.HOUR_CONSULTATION_OVERVIEW, icon: 'mdi-clipboard-outline', route: {name: RouteNames.HOUR_CONSULTATION_OVERVIEW}, activating_routes: []},
        {title: RouteNames.USER_OVERVIEW, icon: 'mdi-face-man', route: {name: RouteNames.USER_OVERVIEW}, activating_routes: []},
        {title: RouteNames.TEAM_OVERVIEW, icon: 'mdi-account-multiple', route: {name: RouteNames.TEAM_OVERVIEW}, activating_routes: []},
        {title: RouteNames.INVOICE_OVERVIEW, icon: 'mdi-invoice-text-multiple-outline', route: {name: RouteNames.INVOICE_OVERVIEW}, activating_routes: []},
        {title: RouteNames.PLANNING, icon: 'mdi-calendar-multiple-check', route: {name: RouteNames.PLANNING}, activating_routes: []},
        {title: RouteNames.TASK_OVERVIEW, icon: 'mdi-dns-outline', route: {name: RouteNames.TASK_OVERVIEW}, activating_routes: [RouteNames.TASK_DETAIL]},
        {title: RouteNames.SETTINGS, icon: 'mdi-cog', route: {name: RouteNames.SETTINGS}, activating_routes: []},
      ],
      mobileDrawer: false,          // if isOnMobile, controls whether the full screen mobile menu is shown or not
      drawer: true,                 // the drawer boolean does nothing more than enabling the v-model
      collapsed: false,             // if not isOnMobile, controls whether the side bar is collapsed or not
      prickingTime: null,
      RouteNames
    }
  },
  created() {
    this.$store.dispatch('parameterModule/fetchParamsOrganization')
    this.$store.dispatch("clockingsModule/fetchPricking")
    this.startTimer()
  },
  computed: {
    getNavItems() {
      return this.navItems.filter(navItem => hasAccessToRoute(navItem.route) && organizationHasParameterTeamsByRoute(navItem.route) && routeActionIsEnabled(navItem.route) && organizationHasModuleByRoute(navItem.route))
    },
    getSelectedNavItem() {
      //Check if any of the activating routes of a nav item matches the current route
      //This will make the nav item active
      for (const navItem of this.navItems) {
        for (const routeName of navItem.activating_routes) {
          const resolvedPath = this.$router.resolve({name: routeName, params: this.$route.params}).href
          if (resolvedPath != '/' && this.$route.path.startsWith(resolvedPath)) {
            return navItem
          }
        }
      }
      //Check if any of the nav item routes or their subroutes match the current route
      //This will make the nav item active
      for (const navItem of this.navItems) {
          const resolvedPath = this.$router.resolve({...navItem.route, params: this.$route.params}).href
        if (resolvedPath != '/' && this.$route.path.startsWith(resolvedPath)) {
            return navItem
        }
      }

      //If no active route is found, the user is on a special page like /denied or a 404
      //Return a dummy navItem to prevent any nullpointer errors during render 
      return {title: RouteNames.DENIED, icon: 'mdi-alert-circle', route: {name: RouteNames.DENIED}, activating_routes: []};
    },
    isOnMobile() {
      return this.$vuetify.breakpoint.smAndDown
    },
    getMobileCollapsed() {
      // like the collapsed boolean, but ignore the actual value when on mobile
      if (this.isOnMobile) return false
      return this.collapsed
    },
    getNavBarWidth() {
      let width = 160

      if (this.isOnMobile) {
        if (this.mobileDrawer) {
          return "100vw"
        } else {
          return 0
        }
        
      } else if(this.collapsed) {
        width = 70
      }

      return width
    },
    getPricking() {
      if (this.$store.state.clockingsModule.pricking?.startTime) {
        return DetailedTimeStamp.fromJson(this.$store.state.clockingsModule.pricking?.startTime).getUTCTimeAsDate()
      } else {
        return null
      }
    }
  },
  methods: {
    checkRouteForChildren(routeName) {
      // To be used while a switch in route is happening and computed properties are not yet up to date
      const routes = this.$router.getRoutes()
      const foundChildren = routes.filter(r => r.parent?.name === routeName)

      return foundChildren.length > 0
    },
    toggleCollapsed() {
      this.collapsed = !this.collapsed
    },
    toggleMobileDrawer() {
      this.mobileDrawer = !this.mobileDrawer
    },
    toggleSubDrawer() {
      if (this.isOnMobile) {
        this.$refs.subDrawerRef.subDrawer = !this.$refs.subDrawerRef.subDrawer
      }
    },
    startTimer() {
      setInterval(() => {
        if (this.getPricking) {
          const duration = intervalToDuration({start: this.getPricking, end: new Date()})
          if (duration.days > 0 || duration.weeks > 0 || duration.months > 0 || duration.years > 0) {
            this.prickingTime = "> 24 uur"
          } else {
            this.prickingTime = `${duration.hours.toString().padStart(2, "0")}:${duration.minutes.toString().padStart(2, "0")}:${duration.seconds.toString().padStart(2, "0")}`
          }
        }
      }, 1000);
    },
    clickNavBarHeader() {
      if (this.isOnMobile) {
        this.toggleMobileDrawer()
      } else {
        this.toggleCollapsed()
      }
    },
    clickNavBar(routeName){
      // Check if we are navigating to the subdrawer, otherwise close main drawer
      if(this.checkRouteForChildren(routeName)) {
        this.toggleSubDrawer()
      } else if(this.isOnMobile){
        this.toggleMobileDrawer()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.v-navigation-drawer__content .theme--light.v-list-item.v-list-item--active {
  -webkit-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  -moz-box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);
  box-shadow: inset 6px 0 0 0 var(--v-nav-text-active-base);

  .v-list-item__content, .v-list-item__icon,
  .v-list-item__content, .v-list-item__icon :before {
    color: var(--v-nav-text-active-base) !important;
  }
}

.v-list-item__icon {
  min-width: fit-content !important;
}

.nav-menu__item {
  height: 40px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    max-height: 40px;
    min-height: 25px;
    height: 8vh;
  }

  &:hover {
    -webkit-box-shadow: inset 6px 0 0 0 $fossil-gray;
    -moz-box-shadow: inset 6px 0 0 0 $fossil-gray;
    box-shadow: inset 6px 0 0 0 $cadet-gray;
    background-color: $yankee-blue-hover;
  }
}

.nav-menu__text {
  font-size: 14px;
}

.no-after::after{
  content: none;
}

#hoverCollapse:hover{
  background-color: $yankee-blue-hover;
}

</style>
